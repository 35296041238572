import React, { Component } from "react"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import { Banner, PaddingWrapper, Table } from "@igloonet-web/shared-ui"

class PaymentInfo extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Banner>
          <h1>Payment info</h1>
          <p>Detail information about payments</p>
        </Banner>

        <PaddingWrapper>
          <Container>
            <h2>Payment from Czechia</h2>
            <div className="font-weight-bold">Raiffeisenbank a.s.</div>
            <div>Account number: 7261610001/5500</div>
            <div>Payments in CZK or EUR</div>
            <br />
            <div className="font-weight-bold">Fio banka, a.s.</div>
            <div>Account number: 2500090814/2010</div>
            <div>Payments only in CZK</div>
            <br />
            <div className="font-weight-bold">Komerční banka, a.s.</div>
            <div>Account number: 115-4387440227/0100</div>
            <div>Payments only in CZK</div>

            <h2 className="mt-5">Payment from EU</h2>
            <div>
              Payments <b>only in EUR currency in SEPA mode with SHA</b>
              payment information.
            </div>
            <div>
              <b>Fio banka, a.s.</b>
              <em>,V Celnici 1028/10, Praha 1, 117 21, Czech Repubdivc (CZ)</em>
            </div>
            <div>IBAN: CZ7720100000002600104558</div>
            <div>BIC/SWIFT: FIOBCZPPXXX</div>

            <div className="mt-5">
              <h2>Payment from other countries</h2>
              <div>
                You need to enter reference number (or variable symbol/specific
                symbol) exactly as on invoice:
                <b>/VS/11110033.</b>
              </div>
              <div>
                If you have invoice where is VS in format of simple number (as{" "}
                <b>11110033</b>) just enter <b>/VS/ </b>
                before it.
              </div>

              <h3 className="mt-5">If your invoice is in EUR</h3>
              <div>
                Choose <b>SHA </b>
                or <b>OUR </b>
                payment instruction.
              </div>
              <Table
                ACnumber="2600104558/2010"
                IBANnumber="CZ77 2010 0000 0026 0010 4558"
              />
              <h3 className="mt-5">If your invoice is in CZK</h3>
              <div>
                Only <b>OUR payment instruction </b>
                is possible.
              </div>
              <Table
                ACnumber="2500090814/2010"
                IBANnumber="CZ96 2010 0000 0025 0009 0814"
              />
            </div>
          </Container>
        </PaddingWrapper>
      </Layout>
    )
  }
}

export default PaymentInfo
